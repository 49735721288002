import axios from 'axios';
import * as types from './QuickPostageEscrow.types';
import * as selectors from './QuickPostageEscrow.selectors';
import { validateAuthInResponse, showNotification, empty } from './../../utils';
import fileDownload from 'js-file-download';
import moment from 'moment';

const apiUrl = process.env.REACT_APP_API_URL;

export const fetchCustomers = () => (dispatch) => {
    dispatch({ type: types.FETCH_CUSTOMERS, payload: null });
    axios.get(apiUrl + '/invoice/fetch-customers',
        { headers: { 'Authorization': `Bearer ${localStorage.getItem('access_token')}` } }
    ).then((response) => {
        dispatch({ type: types.FETCH_CUSTOMERS_SUCCESS, payload: response.data });
    }).catch((error) => {
        dispatch(validateAuthInResponse(error, types.FETCH_CUSTOMERS_ERROR));
    });
}

export const fetchSalesCustomers = (customerId) => (dispatch, getState) => {
    dispatch({ type: types.FETCH_SALES_CUSTOMERS, payload: null });
    axios.get(apiUrl + '/invoice/fetch-sales-customers?id=' + customerId,
        { headers: { 'Authorization': `Bearer ${localStorage.getItem('access_token')}` } }
    ).then((response) => {
        dispatch({ type: types.FETCH_SALES_CUSTOMERS_SUCCESS, payload: response.data });
    }).catch((error) => {
        dispatch(validateAuthInResponse(error, types.FETCH_SALES_CUSTOMERS_ERROR));
    });
}

export const fetchReport = () => (dispatch, getState) => {
    const companyId = selectors.getCompanyId(getState());
    const salesId = selectors.getSalesId(getState());
    const fromDate = selectors.getFromDate(getState());
    const toDate = selectors.getToDate(getState());
    const activeTab = selectors.getActiveTab(getState());

    if (parseInt(activeTab) === 1 && (companyId == null || companyId === '')) {
        return showNotification('Select company', 'You must select a company to get a Quick Postage Escrow Report', 'info');
    }
    if (parseInt(activeTab) === 2 && (salesId == null || salesId === '')) {
        return showNotification('Select Sales Rep', 'You must select a Sales Rep to run the Report', 'info');
    }
    if (parseInt(activeTab) === 3 && (salesId == null || salesId === '')) {
        return showNotification('Select Sales Rep', 'You must select a Sales Rep to run the Report', 'info');
    }

    let params = ['type=' + activeTab];
    if (parseInt(activeTab) === 1 && companyId && companyId != null && companyId !== '') {
        params.push('id=' + companyId);
    }
    if (parseInt(activeTab) === 2 && salesId && salesId != null && salesId !== '') {
        params.push('sales-id=' + salesId);
    }
    if (parseInt(activeTab) === 2 && fromDate && fromDate != null && fromDate !== '') {
        params.push('from-date=' + fromDate);
    }
    if (parseInt(activeTab) === 2 && toDate && toDate != null && toDate !== '') {
        params.push('to-date=' + toDate);
    }
    if (parseInt(activeTab) === 3 && salesId && salesId != null && salesId !== '') {
        params.push('sales-id=' + salesId);
    }
    if (parseInt(activeTab) === 3 && fromDate && fromDate != null && fromDate !== '') {
        params.push('from-date=' + fromDate);
    }
    if (parseInt(activeTab) === 3 && toDate && toDate != null && toDate !== '') {
        params.push('to-date=' + toDate);
    }

    dispatch({ type: types.FETCH_REPORT, payload: null });
    axios.get(apiUrl + '/invoice/quick-postage-report?' + params.join('&'),
        { headers: { 'Authorization': `Bearer ${localStorage.getItem('access_token')}` } }
    ).then((response) => {
        dispatch({ type: types.FETCH_REPORT_SUCCESS, payload: response.data });
    }).catch((error) => {
        dispatch(validateAuthInResponse(error, types.FETCH_REPORT_ERROR));
    });
}

export const exportSalesReport = () => (dispatch, getState) => {
    const salesId = selectors.getSalesId(getState());
    const fromDate = selectors.getFromDate(getState());
    const toDate = selectors.getToDate(getState());
    const report = selectors.getReport(getState());

    dispatch({ type: types.EXPORT_SALES_REPORT, payload: null });
    axios.post(apiUrl + '/invoice/export-sales-report', { rows: report.rows, salesId, fromDate, toDate },
        {
            headers: { 'Authorization': `Bearer ${localStorage.getItem('access_token')}` },
            responseType: 'blob',
        })
        .then((response) => {
            dispatch({ type: types.EXPORT_SALES_REPORT_SUCCESS, payload: null });
            fileDownload(response.data, 'Sales Report.xlsx');
        })
        .catch((error) => {
            dispatch(validateAuthInResponse(error, types.EXPORT_SALES_REPORT_ERROR));
        });
}

export const exportCommissionReport = () => (dispatch, getState) => {
    const salesId = selectors.getSalesId(getState());
    const saleReps = selectors.getSaleReps(getState());
    const fromDate = selectors.getFromDate(getState());
    const toDate = selectors.getToDate(getState());

    let salesRepName = '';
    for (let i = 0; i < saleReps.length; i++) {
        if (parseInt(saleReps[i].id) === parseInt(salesId)) {
            salesRepName = saleReps[i].name;
            break;
        }
    }

    dispatch({ type: types.EXPORT_COMMISSION_REPORT, payload: null });
    axios.post(apiUrl + '/invoice/export-commission-report', { salesId, fromDate, toDate },
        {
            headers: { 'Authorization': `Bearer ${localStorage.getItem('access_token')}` },
            responseType: 'blob',
        })
        .then((response) => {
            dispatch({ type: types.EXPORT_COMMISSION_REPORT_SUCCESS, payload: null });
            fileDownload(response.data, salesRepName + ' - Commission Report.xlsx');
        })
        .catch((error) => {
            dispatch(validateAuthInResponse(error, types.EXPORT_COMMISSION_REPORT_ERROR));
        });
}

export const exportARAgingReport = (customerName) => (dispatch, getState) => {
    const report = selectors.getReport(getState())
    const companyId = selectors.getCompanyId(getState());
    dispatch({ type: types.EXPORT_AR_AGING_REPORT, payload: null });
    axios.post(apiUrl + '/invoice/export-ar-aging-report', { id: companyId, rows: report.rows },
        {
            headers: { 'Authorization': `Bearer ${localStorage.getItem('access_token')}` },
            responseType: 'blob',
        })
        .then((response) => {
            dispatch({ type: types.EXPORT_AR_AGING_REPORT_SUCCESS, payload: null });
            fileDownload(response.data, 'AR Aging Report - ' + customerName + '.xlsx');
        })
        .catch((error) => {
            dispatch(validateAuthInResponse(error, types.EXPORT_AR_AGING_REPORT_ERROR));
        });
}

export const exportInvoicesReport = () => (dispatch, getState) => {
    const salesRepId = selectors.getSalesId(getState());
    const customerId = selectors.getCustomerId(getState());
    const selectedDepartments = selectors.getSelectedDepartments(getState());
    const invoiceReportType = selectors.getInvoiceReportType(getState());

    if (parseInt(invoiceReportType) === 1) {
        if (!salesRepId || salesRepId == null || salesRepId === '') {
            return showNotification('Select Sales Rep', 'You need to select a Sales Rep to run the Report', 'info');
        }
    } else if (parseInt(invoiceReportType) === 2) {
        if (!customerId || customerId == null || customerId === '') {
            return showNotification('Select Customer', 'You need to select a Customer to run the Report', 'info');
        }
    }

    if (selectedDepartments.length === 0) {
        return showNotification('Select Departments', 'Select at least one department to export the report', 'info');
    }

    dispatch({ type: types.EXPORT_INVOICES_REPORT, payload: null });
    axios.post(apiUrl + '/invoice/unit-price-report', { salesRepId, customerId, departments: selectedDepartments, type: invoiceReportType },
        {
            headers: { 'Authorization': `Bearer ${localStorage.getItem('access_token')}` },
            responseType: 'blob',
        })
        .then((response) => {
            dispatch({ type: types.EXPORT_INVOICES_REPORT_SUCCESS, payload: null });
            fileDownload(response.data, 'Invoices Report.xlsx');
        })
        .catch((error) => {
            if (error.response && error.response.status === 404) {
                showNotification('No Data', 'There is no data for your selection.', 'info');
                dispatch({ type: types.EXPORT_INVOICES_REPORT_ERROR, payload: null });
            } else {
                dispatch(validateAuthInResponse(error, types.EXPORT_INVOICES_REPORT_ERROR));
            }
        });
}

export const exportCombinedReport = () => (dispatch, getState) => {
    const fromDate = selectors.getFromDate(getState());
    const toDate = selectors.getToDate(getState());

    dispatch({ type: types.EXPORT_COMBINED_REPORT, payload: null });
    axios.post(apiUrl + '/invoice/export-combined-report', { fromDate, toDate },
        {
            headers: { 'Authorization': `Bearer ${localStorage.getItem('access_token')}` },
            responseType: 'blob',
        })
        .then((response) => {
            dispatch({ type: types.EXPORT_COMBINED_REPORT_SUCCESS, payload: null });
            fileDownload(response.data, 'Combined Invoices Report.xlsx');
        })
        .catch((error) => {
            if (error.response && error.response.status === 404) {
                showNotification('No Data', 'There is no data for your selection.', 'info');
                dispatch({ type: types.EXPORT_COMBINED_REPORT_ERROR, payload: null });
            } else {
                dispatch(validateAuthInResponse(error, types.EXPORT_COMBINED_REPORT_ERROR));
            }
        });
}

export const exportShippingReport = () => (dispatch, getState) => {
    const fromDate = selectors.getFromDate(getState());
    const toDate = selectors.getToDate(getState());
    const shippingMethod = selectors.getShippingMethod(getState());

    dispatch({ type: types.EXPORT_SHIPPING_REPORT, payload: null });
    axios.post(apiUrl + '/invoice/export-shipping-report', { fromDate, toDate, shippingMethod },
        {
            headers: { 'Authorization': `Bearer ${localStorage.getItem('access_token')}` },
            responseType: 'blob',
        })
        .then((response) => {
            dispatch({ type: types.EXPORT_SHIPPING_REPORT_SUCCESS, payload: null });
            fileDownload(response.data, 'Shipping Report.xlsx');
        })
        .catch((error) => {
            if (error.response && error.response.status === 404) {
                showNotification('No Data', 'There is no data for your selection.', 'info');
                dispatch({ type: types.EXPORT_SHIPPING_REPORT_ERROR, payload: null });
            } else {
                dispatch(validateAuthInResponse(error, types.EXPORT_INVOICES_REPORT_ERROR));
            }
        });
}

export const exportMailingReport = () => (dispatch, getState) => {
    const toDate = selectors.getToDate(getState());
    if (!toDate || toDate == null || toDate === '') {
        return showNotification('Select Date', 'You need to select a date to run the Report', 'info');
    }

    dispatch({ type: types.EXPORT_MAILING_REPORT, payload: null });
    axios.post(apiUrl + '/pqt-tracking/export-mail-report', { toDate },
        {
            headers: { 'Authorization': `Bearer ${localStorage.getItem('access_token')}` },
            responseType: 'blob',
        })
        .then((response) => {
            dispatch({ type: types.EXPORT_MAILING_REPORT_SUCCESS, payload: null });
            fileDownload(response.data, 'Mailing Report - ' + moment(toDate).format('MM-DD-YYYY') + '.xlsx');
        })
        .catch((error) => {
            if (error.response && error.response.status === 404) {
                showNotification('No Data', 'There is no data for your selection.', 'info');
                dispatch({ type: types.EXPORT_MAILING_REPORT_ERROR, payload: null });
            } else {
                dispatch(validateAuthInResponse(error, types.EXPORT_MAILING_REPORT_ERROR));
            }
        });
}

export const exportYearlySalesReport = () => (dispatch, getState) => {
    const salesRepId = selectors.getSalesId(getState());
    const customerId = selectors.getCustomerId(getState());
    const fromDate = selectors.getFromDate(getState());
    const toDate = selectors.getToDate(getState());

    if (!salesRepId || salesRepId == null || salesRepId === '') {
        return showNotification('Select Sales Rep', 'You need to select a Sales Rep to run the Report', 'info');
    }
    if (!customerId || customerId == null || customerId === '') {
        return showNotification('Select Custoemr', 'You need to select a Customer to run the Report', 'info');
    }

    dispatch({ type: types.EXPORT_YEARLY_SALES_REPORT, payload: null });
    axios.post(apiUrl + '/pqt-tracking/export-yearly-sales-report', { salesRepId, customerId, fromDate, toDate },
        {
            headers: { 'Authorization': `Bearer ${localStorage.getItem('access_token')}` },
            responseType: 'blob',
        })
        .then((response) => {
            dispatch({ type: types.EXPORT_YEARLY_SALES_REPORT_SUCCESS, payload: null });

            let filename = 'Yearly Sales Report';
            if (fromDate !== null && toDate !== null) {
                filename = 'Yearly Sales Report (From ' + moment(fromDate).format('MM-DD-YYYY') + ' to ' + moment(toDate).format('MM-DD-YYYY') + ')';
            } else if (fromDate !== null ) {
                filename = 'Yearly Sales Report (From ' + moment(fromDate).format('MM-DD-YYYY') + ')';
            } else if (toDate !== null) {
                filename = 'Yearly Sales Report (To ' + moment(toDate).format('MM-DD-YYYY') + ')';
            }

            fileDownload(response.data, filename + '.xlsx');
        })
        .catch((error) => {
            if (error.response && error.response.status === 404) {
                showNotification('No Data', 'There is no data for your selection.', 'info');
                dispatch({ type: types.EXPORT_YEARLY_SALES_REPORT_ERROR, payload: null });
            } else {
                dispatch(validateAuthInResponse(error, types.EXPORT_YEARLY_SALES_REPORT_ERROR));
            }
        });
}

export const exportJobStatusReport = () => (dispatch, getState) => {
    const salesRepId = selectors.getSalesId(getState());
    const fromDate = selectors.getFromDate(getState());
    const toDate = selectors.getToDate(getState());
    const jobsType = selectors.getJobsType(getState());

    if (!salesRepId || salesRepId == null || salesRepId === '') {
        return showNotification('Select Sales Rep', 'You need to select a Sales Rep to run the Report', 'info');
    }

    dispatch({ type: types.EXPORT_JOB_STATUS_REPORT, payload: null });
    axios.post(apiUrl + '/pqt-tracking/export-job-status-report', { salesRepId, fromDate, toDate, jobsType },
        {
            headers: { 'Authorization': `Bearer ${localStorage.getItem('access_token')}` },
            responseType: 'blob',
        })
        .then((response) => {
            dispatch({ type: types.EXPORT_JOB_STATUS_REPORT_SUCCESS, payload: null });
            fileDownload(response.data, 'Job Status Report.xlsx');
        })
        .catch((error) => {
            if (error.response && error.response.status === 404) {
                showNotification('No Data', 'There is no data for your selection.', 'info');
                dispatch({ type: types.EXPORT_JOB_STATUS_REPORT_ERROR, payload: null });
            } else {
                dispatch(validateAuthInResponse(error, types.EXPORT_JOB_STATUS_REPORT_ERROR));
            }
        });
}

export const exportWeeklyReport = () => (dispatch, getState) => {
    const salesRepId = selectors.getSalesId(getState());
    const fromDate = selectors.getFromDate(getState());
    const toDate = selectors.getToDate(getState());

    if (!salesRepId || salesRepId == null || salesRepId === '') {
        return showNotification('Select Sales Rep', 'You need to select a Sales Rep to run the Report', 'info');
    }

    dispatch({ type: types.EXPORT_WEEKLY_REPORT, payload: null });
    axios.post(apiUrl + '/pqt-tracking/export-weekly-report', { salesRepId, fromDate, toDate },
        {
            headers: { 'Authorization': `Bearer ${localStorage.getItem('access_token')}` },
            responseType: 'blob',
        })
        .then((response) => {
            dispatch({ type: types.EXPORT_WEEKLY_REPORT_SUCCESS, payload: null });
            fileDownload(response.data, 'Weekly Sales Report.xlsx');
        })
        .catch((error) => {
            if (error.response && error.response.status === 404) {
                showNotification('No Data', 'There is no data for your selection.', 'info');
                dispatch({ type: types.EXPORT_WEEKLY_REPORT_ERROR, payload: null });
            } else {
                dispatch(validateAuthInResponse(error, types.EXPORT_WEEKLY_REPORT_ERROR));
            }
        });
}

export const uploadFile = (file) => (dispatch, getState) => {
    const salesId = selectors.getSalesId(getState());
    const paidDate = selectors.getPaidDate(getState());

    if (empty(paidDate)) {
        return showNotification('Select Paid Date', 'Select the Paid Date for this commission.', 'info');
    }

    let data = new FormData();
    data.append('salesId', salesId);
    data.append('file', file);
    data.append('paidDate', paidDate);

    dispatch({ type: types.UPLOAD_FILE, payload: null });
    axios.post(apiUrl + '/invoice/upload-file', data,
        { headers: { 'Authorization': `Bearer ${localStorage.getItem('access_token')}` } })
        .then((response) => {
            dispatch({ type: types.UPLOAD_FILE_SUCCESS, payload: response.data });
            showNotification('Success', 'The file was uploaded successfully', 'success');
        })
        .catch((error) => {
            if (error.response.status === 400) {
                console.log(error.response.data.error.message)
                showNotification('Wrong File Format', error.response.data.error.message, 'warning');
            } else {
                dispatch(validateAuthInResponse(error, types.UPLOAD_FILE_ERROR));
            }
        });
}

export const uploadShippingFile = (file) => (dispatch, getState) => {
    let data = new FormData();
    data.append('file', file);

    dispatch({ type: types.UPLOAD_SHIPPING_FILE, payload: null });
    axios.post(apiUrl + '/invoice/upload-shipping-file', data,
        { headers: { 'Authorization': `Bearer ${localStorage.getItem('access_token')}` } })
        .then((response) => {
            dispatch({ type: types.UPLOAD_SHIPPING_FILE_SUCCESS, payload: response.data });
            showNotification('Success', 'The file was uploaded successfully', 'success');
        })
        .catch((error) => {
            if (error.response.status === 400) {
                console.log(error.response.data.error.message)
                showNotification('Wrong File Format', error.response.data.error.message, 'warning');
            } else {
                dispatch(validateAuthInResponse(error, types.UPLOAD_SHIPPING_FILE_ERROR));
            }
        });
}

export const sendSalesReport = () => (dispatch, getState) => {
    const salesId = selectors.getSalesId(getState());
    const fromDate = selectors.getFromDate(getState());
    const toDate = selectors.getToDate(getState());
    const report = selectors.getReport(getState());

    if (parseInt(salesId) === 0) {
        return showNotification('Select Sales Rep', 'Please, select an indiviual Sales Rep to send the report', 'info');
    }

    dispatch({ type: types.SEND_SALES_REPORT, payload: null });
    axios.post(apiUrl + '/invoice/send-sales-report', { rows: report.rows, salesId, fromDate, toDate },
        { headers: { 'Authorization': `Bearer ${localStorage.getItem('access_token')}` }, })
        .then((response) => {
            dispatch({ type: types.SEND_SALES_REPORT_SUCCESS, payload: null });
            showNotification('Success', 'The Sales Report has been sent successfully', 'success');
        })
        .catch((error) => {
            dispatch(validateAuthInResponse(error, types.SEND_SALES_REPORT_ERROR));
        });
}

export const sendSalesNormReport = () => (dispatch, getState) => {
    const salesId = selectors.getSalesId(getState());
    const fromDate = selectors.getFromDate(getState());
    const toDate = selectors.getToDate(getState());
    const report = selectors.getReport(getState());

    if (parseInt(salesId) === 0) {
        return showNotification('Select Sales Rep', 'Please, select an indiviual Sales Rep to send the report', 'info');
    }

    dispatch({ type: types.SEND_SALES_NORM_REPORT, payload: null });
    axios.post(apiUrl + '/invoice/send-sales-report', { rows: report.rows, salesId, fromDate, toDate, toNorm: 1 },
        { headers: { 'Authorization': `Bearer ${localStorage.getItem('access_token')}` }, })
        .then((response) => {
            dispatch({ type: types.SEND_SALES_NORM_REPORT_SUCCESS, payload: null });
            showNotification('Success', 'The Sales Report has been sent successfully', 'success');
        })
        .catch((error) => {
            dispatch(validateAuthInResponse(error, types.SEND_SALES_NORM_REPORT_ERROR));
        });
}

export const setCompanyId = (value) => (dispatch) => {
    dispatch({
        type: types.SET_COMPANY_ID,
        payload: value,
    });
}

export const setCustomerId = (value) => (dispatch) => {
    dispatch({
        type: types.SET_CUSTOMER_ID,
        payload: value,
    });
}

export const setComapny = (value) => (dispatch) => {
    dispatch({
        type: types.SET_COMPANY,
        payload: value,
    });
}

export const setActiveTab = (value) => (dispatch) => {
    dispatch({
        type: types.SET_ACTIVE_TAB,
        payload: value,
    });
}

export const setSalesId = (id) => (dispatch) => {
    dispatch({
        type: types.SET_SALES_ID,
        payload: id,
    });
}

export const setFromDate = (value) => (dispatch) => {
    dispatch({
        type: types.SET_FROM_DATE,
        payload: value,
    });
}

export const setPaidDate = (value) => (dispatch) => {
    dispatch({
        type: types.SET_PAID_DATE,
        payload: value,
    });
}

export const setToDate = (value) => (dispatch) => {
    dispatch({
        type: types.SET_TO_DATE,
        payload: value,
    });
}

export const resetReport = () => (dispatch) => {
    dispatch({
        type: types.RESET_REPORT,
        payload: null,
    });
}

export const setFile = (file) => (dispatch) => {
    dispatch({
        type: types.SET_FILE,
        payload: file,
    });
}

export const checkDepartment = (id, value) => (dispatch) => {
    dispatch({
        type: types.CHECK_DEPARTMENT,
        payload: { id: parseInt(id), value },
    });
}

export const setInvoiceReportType = (value) => (dispatch) => {
    dispatch({
        type: types.SET_INVOICE_REPORT_TYPE,
        payload: value,
    });
}

export const setSalesReportInfoChanged = (value) => (dispatch) => {
    dispatch({
        type: types.SET_SALES_REPORT_INFO_CHANGED,
        payload: value,
    });
}

export const setShippingMethod = (value) => (dispatch) => {
    dispatch({
        type: types.SET_SHIPPING_METHOD,
        payload: value,
    });
}

export const setJobsType = (value) => (dispatch) => {
    dispatch({
        type: types.SET_JOBS_TYPE,
        payload: value,
    });
}

export const checkAll = (value) => (dispatch, getState) => {
    const departments = selectors.getDepartments(getState());

    dispatch({
        type: types.CHECK_ALL,
        payload: { departments, value },
    });
}

export const resetState = () => (dispatch) => {
    dispatch({
        type: types.RESET_STATE,
        payload: null,
    });
}
