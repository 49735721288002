import React, { Fragment } from 'react';
import { Dialog } from './../../common/dialog';
import numeral from 'numeral';
import moment from 'moment-timezone';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import 'react-day-picker/lib/style.css';
import { formatDate, parseDate } from 'react-day-picker/moment';
import 'react-tippy/dist/tippy.css';
import { Tooltip } from 'react-tippy';
import { Link } from 'react-router-dom';
import { useDropzone } from 'react-dropzone';

const Form = React.memo((props) => {
    const {
        acceptedFiles,
        // fileRejections,
        getRootProps,
        getInputProps
    } = useDropzone({
        multiple: false,
        accept: '.xlsx',
    });

    const hideHandler = () => {
        props.resetState();
        props.hideQuickPostageModal();
    };

    const handleReport = () => {
        props.fetchReport();
    };

    const handleSetCompanyId = (event) => {
        props.setCompanyId(event.target.value);
    };

    const handleSetCustomerId = (event) => {
        props.setCustomerId(event.target.value);
    };

    const handleSetSalesId = (event) => {
        props.setSalesId(event.target.value);
        props.setSalesReportInfoChanged(true);
    };

    const handleSetJobsType = (event) => {
        props.setJobsType(event.target.value);
    };

    const handleSetSalesIdInvoicesReport = (event) => {
        props.setSalesId(event.target.value);
        props.fetchSalesCustomers(event.target.value);
    };

    const handleSetShippingMethod = (event) => {
        props.setShippingMethod(event.target.value);
    };

    let customerOptions = [<option value="">Select company</option>];
    for (let i = 0; i < props.customers.length; i++) {
        customerOptions.push(<option value={props.customers[i].id}>{props.customers[i].display_name}</option>);
    }

    const renderPostageItems = () => {
        if (props.report == null || props.report.postage_report.sc_jobs.length === 0) {
            return (
                <tr>
                    <td colSpan="4">No pending invoices in StageCoach</td>
                </tr>
            );
        }

        return props.report.postage_report.sc_jobs.map((job) => {
            return (
                <tr key={job.id}>
                    <td>{job.id}</td>
                    <td>{job.job_description}</td>
                    <td>{job.location}</td>
                    <td className="text-right">{numeral(job.balance).format('$0,0.00')}</td>
                </tr>
            );
        });
    };

    const renderARAgingItems = () => {
        if (props.report == null) {
            return (
                <tr>
                    <td colSpan="5">No pending invoices in StageCoach</td>
                </tr>
            );
        }

        return (
            <tr>
                <td className="text-center">{numeral(props.report.aged_receivables_report.current).format('$0,0.00')}</td>
                <td className="text-center">{numeral(props.report.aged_receivables_report.one_thirty).format('$0,0.00')}</td>
                <td className="text-center">{numeral(props.report.aged_receivables_report.thirtyone_sixty).format('$0,0.00')}</td>
                <td className="text-center">{numeral(props.report.aged_receivables_report.sixtyone_ninety).format('$0,0.00')}</td>
                <td className="text-center">{numeral(props.report.aged_receivables_report.over).format('$0,0.00')}</td>
            </tr>
        );
    };

    const renderSalesReportRows = () => {
        if (props.report == null) {
            return (
                <tr>
                    <td colSpan="13">No pending invoices in StageCoach</td>
                </tr>
            );
        }

        let comissionSalesTotal = 0;
        let shippingSalesTotal = 0;
        let taxTotal = 0;
        let postageSalesTotal = 0;
        let postageTotal = 0;
        let paymentForServicesTotal = 0;
        let storageTotal = 0;
        let convenienceFeeTotal = 0;
        let customerPermitTotal = 0;
        let salesCreditMemoTotal = 0;
        let total = 0;
        let rows = props.report.rows.map((row) => {
            comissionSalesTotal += row.comission_sales;
            shippingSalesTotal += row.shipping_sales;
            taxTotal += row.tax;
            postageSalesTotal += row.postage_sales;
            postageTotal += row.postage;
            paymentForServicesTotal += row.payment_for_services;
            storageTotal += row.storage;
            convenienceFeeTotal += row.convenience_fee;
            customerPermitTotal += row.customer_permit;
            salesCreditMemoTotal += row.sales_credit_memo;
            total += row.total;

            let diffClassName = '';
            let postageSales = numeral(Math.abs(row.postage_sales)).format('$0,0.00');
            let postage = numeral(Math.abs(row.postage + row.customer_permit)).format('$0,0.00');
            if (postageSales !== postage) {
                diffClassName = ' diff';
            }

            let difference = row.postage_sales + row.customer_permit + row.postage;
            return (
                <tr key={row.name}>
                    <td className="text-left">{row.name}</td>
                    <td className="text-right">{numeral(row.comission_sales).format('$0,0.00')}</td>
                    <td className="text-right">{numeral(row.shipping_sales).format('$0,0.00')}</td>
                    <td className="text-right">{numeral(row.tax).format('$0,0.00')}</td>
                    <td className={'text-right' + diffClassName}>{numeral(row.postage_sales).format('$0,0.00')}</td>
                    <td className={'text-right' + diffClassName}>{numeral(row.customer_permit).format('$0,0.00')}</td>
                    <td className={'text-right' + diffClassName}>{numeral(row.postage).format('$0,0.00')}</td>
                    <td className={'text-right' + diffClassName}>{numeral(row.payment_for_services).format('$0,0.00')}</td>
                    <td className={'text-right' + diffClassName}>{numeral(difference.toFixed(2)).format('$0,0.00')}</td>
                    <td className="text-right">{numeral(row.storage).format('$0,0.00')}</td>
                    <td className="text-right">{numeral(row.convenience_fee).format('$0,0.00')}</td>
                    <td className="text-right">{numeral(row.sales_credit_memo).format('$0,0.00')}</td>
                    <td className="text-right">{numeral(row.total).format('$0,0.00')}</td>
                    {/* <td className="text-right">{numeral(row.total).format('$0,0.00')}</td> */}
                </tr>
            );
        });

        if (parseInt(props.salesId) === 0) {
            let difference = postageSalesTotal + customerPermitTotal + postageTotal;
            rows.push(
                <tr key="sales-totals">
                    <td className="text-left"><b>Total:</b></td>
                    <td className="text-right"><b>{numeral(comissionSalesTotal).format('$0,0.00')}</b></td>
                    <td className="text-right"><b>{numeral(shippingSalesTotal).format('$0,0.00')}</b></td>
                    <td className="text-right"><b>{numeral(taxTotal).format('$0,0.00')}</b></td>
                    <td className="text-right"><b>{numeral(postageSalesTotal).format('$0,0.00')}</b></td>
                    <td className="text-right"><b>{numeral(customerPermitTotal).format('$0,0.00')}</b></td>
                    <td className="text-right"><b>{numeral(postageTotal).format('$0,0.00')}</b></td>
                    <td className="text-right"><b>{numeral(paymentForServicesTotal).format('$0,0.00')}</b></td>
                    <td className="text-right"><b>{numeral(difference.toFixed(2)).format('$0,0.00')}</b></td>
                    <td className="text-right"><b>{numeral(storageTotal).format('$0,0.00')}</b></td>
                    <td className="text-right"><b>{numeral(convenienceFeeTotal).format('$0,0.00')}</b></td>
                    <td className="text-right"><b>{numeral(salesCreditMemoTotal).format('$0,0.00')}</b></td>
                    <td className="text-right"><b>{numeral(total).format('$0,0.00')}</b></td>
                    {/* <td className="text-right"></td> */}
                </tr>
            );
        }

        return rows;
    };

    let reportButtonLabel = <Fragment><i className="fas fa-table" /> Search</Fragment>;
    if (props.isFechingReport) {
        reportButtonLabel = <i className="fas fa-circle-notch fa-spin" />;
    }

    const handleSetFromDate = (event) => {
        let value = event === undefined ? '' : moment(event).tz('America/Chicago').format('YYYY-MM-DD');
        props.setFromDate(value);
        props.setSalesReportInfoChanged(true);
    };

    const handleSetToDate = (event) => {
        let value = event === undefined ? '' : moment(event).tz('America/Chicago').format('YYYY-MM-DD');
        props.setToDate(value);
        props.setSalesReportInfoChanged(true);
    };

    const handleSetPaidDate = (event) => {
        let value = event === undefined ? '' : moment(event).tz('America/Chicago').format('YYYY-MM-DD');
        props.setPaidDate(value);
    };

    let selectedCustomer = null;
    for (let i = 0; i < props.customers.length; i++) {
        if (parseInt(props.companyId) === parseInt(props.customers[i].id)) {
            selectedCustomer = props.customers[i];
            break;
        }
    }

    const invoicesWithDifferences = () => {
        if (props.report == null) {
            return null;
        }

        let invoices = props.report.map((invoice) => {
            return <li><Link to={'/invoice/' + invoice.id} target="_blank" rel="noopener noreferrer">{invoice.qb_number}</Link> - ({invoice.problem})</li>;
        });

        return (
            <div>
                The following invoices have differences in QB, please review and correct them before you can run the report:
                <ul>
                    {invoices}
                </ul>
            </div>
        );
    };

    let qbTotal = 0;
    let totalPostage = 0;
    let finalPostageClass = 'final-postage';
    let content = null;
    let activeTab = parseInt(props.activeTab);
    if (activeTab === 1) {  // Customer Report
        if (props.report != null && props.report.postage_report.qb_total) {
            qbTotal = props.report.postage_report.qb_total;
        }

        if (props.report != null && props.report.postage_report.total) {
            totalPostage = props.report.postage_report.total;
        }

        if (totalPostage < 0) {
            finalPostageClass = 'final-postage red';
        }

        let exportARAgingReportButtonLabel = <Fragment><i class="fas fa-file-excel" /> Export</Fragment>;
        if (props.exportingARAgingReport) {
            exportARAgingReportButtonLabel = <i className="fas fa-circle-notch fa-spin" />;
        }

        let exportButton = null;
        if (props.report !== null) {
            exportButton = <button className="report-button" onClick={props.exportARAgingReport.bind(this, selectedCustomer.display_name)} disabled={props.exportingARAgingReport}>{exportARAgingReportButtonLabel}</button>;
        }

        content = (
            <Fragment>
                <div className="field">
                    Company Name:
                    <select value={props.companyId} onChange={handleSetCompanyId}>
                        {customerOptions}
                    </select>
                    <button onClick={handleReport} disabled={props.isFechingReport}>{reportButtonLabel}</button>
                </div>
                <div className="postage-report">
                    <div className="title-container">
                        <div className="postage-title">Postage Escrow</div>
                        <div className="qb-total">QuickBooks: {numeral(qbTotal).format('$0,0.00')}</div>
                    </div>
                    <div className="postage-table">
                        <table className="table table-sm table-hover table-striped">
                            <thead>
                                <tr>
                                    <th>SC #</th>
                                    <th>Description</th>
                                    <th>Location</th>
                                    <th className="text-right">Amount</th>
                                </tr>
                            </thead>
                            <tbody>{renderPostageItems()}</tbody>
                        </table>
                    </div>
                    <div className="summary-container">
                        <div className="summary">
                            <div><b>After all orders are processed:</b></div>
                            <div>Postage Escrow: <span className={finalPostageClass}>{numeral(totalPostage).format('$0,0.00')}</span></div>
                        </div>
                    </div>
                </div>
                <div className="ar-aging-report">
                    <div className="title-container">
                        <div className="ar-aging-title">AR Aging Report</div>
                        {exportButton}
                    </div>
                    <div className="ar-aging-table">
                        <table className="table table-sm table-hover table-striped">
                            <thead>
                                <tr>
                                    <th className="text-center">Current</th>
                                    <th className="text-center">1-30</th>
                                    <th className="text-center">31-60</th>
                                    <th className="text-center">61-90</th>
                                    <th className="text-center">91 and over</th>
                                </tr>
                            </thead>
                            <tbody>{renderARAgingItems()}</tbody>
                        </table>
                    </div>
                </div>
                <div className="customer-balance-report">
                    <div className="title-container">
                        <div className="customer-balance-title">Customer Balance Report</div>
                    </div>
                    <div className="customer-balance-table">
                        Customer Balance: <b>{numeral(props.report != null ? props.report.customer_balance_report.total : 0).format('$0,0.00')}</b>
                    </div>
                </div>
            </Fragment>
        );
    } else if (activeTab === 2) {   // Sales Report
        let salesOptions = [<option value="">Select Sales Rep</option>, <option value="0" className="all-sales">All Sales Rep</option>];
        for (let i = 0; i < props.invoicesSalesReps.length; i++) {
            salesOptions.push(<option value={props.invoicesSalesReps[i].id}>{props.invoicesSalesReps[i].name}</option>);
        }

        let exportSalesReportButtonLabel = <Fragment><i class="fas fa-file-excel" /> Export</Fragment>;
        if (props.exportingSalesReport) {
            exportSalesReportButtonLabel = <i className="fas fa-circle-notch fa-spin" />;
        }

        let sendSalesReportButtonLabel = <Fragment><i class="fas fa-envelope" /> Send</Fragment>;
        if (props.sendingSalesReport) {
            sendSalesReportButtonLabel = <i className="fas fa-circle-notch fa-spin" />;
        }

        let sendSalesNormReportButtonLabel = <Fragment><i class="fas fa-envelope" /> Send to Norm</Fragment>;
        if (props.sendingSalesNormReport) {
            sendSalesNormReportButtonLabel = <i className="fas fa-circle-notch fa-spin" />;
        }

        let exportButton = null;
        let sendButton = null;
        let sendNormButton = null;
        if (props.report !== null && !props.salesReportInfoChanged) {
            exportButton = <button className="sales-report-button export-button" onClick={props.exportSalesReport} disabled={props.exportingSalesReport}>{exportSalesReportButtonLabel}</button>;
            sendButton = <button className="sales-report-button export-button" onClick={props.sendSalesReport} disabled={props.sendingSalesReport}>{sendSalesReportButtonLabel}</button>;
            sendNormButton = <button className="sales-norm-report-button export-button" onClick={props.sendSalesNormReport} disabled={props.sendingSalesNormReport}>{sendSalesNormReportButtonLabel}</button>;
        }

        let salesTotal = 0;
        if (props.report != null) {
            for (let i = 0; i < props.report.rows.length; i++) {
                salesTotal += props.report.rows[i].total;
            }
        }

        content = (
            <Fragment>
                <div className="field">
                    Sales:
                    <select value={props.salesId} onChange={handleSetSalesId} className="sales-reps-select">
                        {salesOptions}
                    </select>
                    From:
                    <DayPickerInput
                        formatDate={formatDate}
                        parseDate={parseDate}
                        component={(props) => {
                            let value = props.value;
                            if (!props.value) {
                                value = '';
                            }
                            return <input {...props} value={value} />;
                        }}
                        placeholder=""
                        className="date-picker-field"
                        onDayChange={handleSetFromDate}
                        value={props.fromDate == null || props.fromDate === '0000-00-00' ? null : moment(props.fromDate).format('MM/DD/YYYY')}
                    />
                    <Tooltip title="Clear Date" position="bottom" arrow size="small" className="action-tooltip">
                        <i className="far fa-calendar-times" onClick={props.setFromDate.bind(this, null)} />
                    </Tooltip>
                    To:
                    <DayPickerInput
                        formatDate={formatDate}
                        parseDate={parseDate}
                        component={(props) => {
                            let value = props.value;
                            if (!props.value) {
                                value = '';
                            }
                            return <input {...props} value={value} />;
                        }}
                        placeholder=""
                        className="date-picker-field"
                        onDayChange={handleSetToDate}
                        value={props.toDate == null || props.toDate === '0000-00-00' ? '' : moment(props.toDate).format('MM/DD/YYYY')}
                    />
                    <Tooltip title="Clear Date" position="bottom" arrow size="small" className="action-tooltip">
                        <i className="far fa-calendar-times" onClick={props.setToDate.bind(this, null)} />
                    </Tooltip>
                    <button className="sales-report-button" onClick={handleReport} disabled={props.isFechingReport}>{reportButtonLabel}</button>
                    {exportButton}
                    {sendButton}
                    {sendNormButton}
                </div>
                <div className="sales-report-table">
                    <table className="table table-sm table-hover table-striped">
                        <thead>
                            <tr>
                                <th className="text-left">Name</th>
                                <th className="text-right commissionable-sales-column">Commissionable Sales</th>
                                <th className="text-right">Shipping</th>
                                <th className="text-right">Tax</th>
                                <th className="text-right">Postage Sales</th>
                                <th className="text-right">Customer Permit</th>
                                <th className="text-right">Postage Collected</th>
                                <th className="text-right">Payment for Services</th>
                                <th className="text-right">Difference</th>
                                <th className="text-right">Storage</th>
                                <th className="text-right convenience-fee-column">Convenience Fee</th>
                                <th className="text-right credit-memo-column">Sales Credit Memo</th>
                                <th className="text-right">Total Sales</th>
                                {/* <th className="text-right"></th> */}
                            </tr>
                        </thead>
                        <tbody>{renderSalesReportRows()}</tbody>
                    </table>
                    <div className="total-sales-container">
                        <b>Total Sales: {numeral(salesTotal).format('$0,0.00')}</b>
                    </div>
                </div>
            </Fragment>
        );
    } else if (activeTab === 3) {   // Commission Report
        let salesOptions = [<option value="">Select Sales Rep</option>];
        for (let i = 0; i < props.salesRep.length; i++) {
            salesOptions.push(<option value={props.salesRep[i].id}>{props.salesRep[i].name}</option>);
        }

        let exportCommissionReportButtonLabel = <Fragment><i class="fas fa-file-excel" /> Export</Fragment>;
        if (props.exportingCommissionReport) {
            exportCommissionReportButtonLabel = <i className="fas fa-circle-notch fa-spin" />;
        }

        let reportInfo = null;
        if (props.report !== null && props.report.length === 0) {
            reportInfo = (
                <div>The report is ready, click on the Export button to download the file.</div>
            );
        } else if (props.report !== null && props.report.length > 0) {
            reportInfo = (
                <div>{invoicesWithDifferences()}</div>
            );
        }

        const handleUploadFile = () => {
            props.uploadFile(acceptedFiles[0]);
        };

        let selectedFile = null;
        let uploadButton = null;
        if (acceptedFiles.length > 0) {
            selectedFile = (
                <div className="selected-file">
                    <b>Selected File:</b> {acceptedFiles[0].name}
                </div>
            );

            let uploadButtonLabel = <Fragment><i className="fas fa-file-upload" /> Upload</Fragment>;
            if (props.uploadingFile) {
                uploadButtonLabel = <i className="fas fa-circle-notch fa-spin" />;
            }
            uploadButton = <button className="upload-button" onClick={handleUploadFile} disabled={props.uploadingFile}>{uploadButtonLabel}</button>;
        }

        content = (
            <Fragment>
                <div className="field">
                    Sales:
                    <select value={props.salesId} onChange={handleSetSalesId} className="sales-reps-select">
                        {salesOptions}
                    </select>
                    From:
                    <DayPickerInput
                        formatDate={formatDate}
                        parseDate={parseDate}
                        component={(props) => {
                            let value = props.value;
                            if (!props.value) {
                                value = '';
                            }
                            return <input {...props} value={value} />;
                        }}
                        placeholder=""
                        className="date-picker-field"
                        onDayChange={handleSetFromDate}
                        value={props.fromDate == null || props.fromDate === '0000-00-00' ? null : moment(props.fromDate).format('MM/DD/YYYY')}
                    />
                    <Tooltip title="Clear Date" position="bottom" arrow size="small" className="action-tooltip">
                        <i className="far fa-calendar-times" onClick={props.setFromDate.bind(this, null)} />
                    </Tooltip>
                    To:
                    <DayPickerInput
                        formatDate={formatDate}
                        parseDate={parseDate}
                        component={(props) => {
                            let value = props.value;
                            if (!props.value) {
                                value = '';
                            }
                            return <input {...props} value={value} />;
                        }}
                        placeholder=""
                        className="date-picker-field"
                        onDayChange={handleSetToDate}
                        value={props.toDate == null || props.toDate === '0000-00-00' ? '' : moment(props.toDate).format('MM/DD/YYYY')}
                    />
                    <Tooltip title="Clear Date" position="bottom" arrow size="small" className="action-tooltip">
                        <i className="far fa-calendar-times" onClick={props.setToDate.bind(this, null)} />
                    </Tooltip>
                    <button className="sales-report-button" onClick={props.exportCommissionReport} disabled={props.exportingCommissionReport}>{exportCommissionReportButtonLabel}</button>
                </div>
                <div className="upload-file">
                    Upload Commission Report:
                    <div {...getRootProps({ className: 'dropzone-container' })}>
                        <input {...getInputProps()} />
                        Click here to select file
                    </div>
                    <div className="paid-date-field">
                        Paid Date:
                        <DayPickerInput
                            formatDate={formatDate}
                            parseDate={parseDate}
                            component={(props) => {
                                let value = props.value;
                                if (!props.value) {
                                    value = '';
                                }
                                return <input {...props} value={value} />;
                            }}
                            placeholder=""
                            className="date-picker-field"
                            onDayChange={handleSetPaidDate}
                            value={props.paidDate == null || props.paidDate === '0000-00-00' ? null : moment(props.paidDate).format('MM/DD/YYYY')}
                        />
                    </div>
                    {uploadButton}
                </div>
                {selectedFile}
                <div className="sales-report-table">
                    {reportInfo}
                </div>
            </Fragment>
        );
    } else if (activeTab === 4) {   // Unit Cost Report
        let salesOptions = [<option value="">Select Sales Rep</option>];
        for (let i = 0; i < props.salesRep.length; i++) {
            salesOptions.push(<option value={props.salesRep[i].id}>{props.salesRep[i].name}</option>);
        }

        let exportInvoicesReportButtonLabel = <Fragment><i class="fas fa-file-excel" /> Export</Fragment>;
        if (props.exportingInvoicesReport) {
            exportInvoicesReportButtonLabel = <i className="fas fa-circle-notch fa-spin" />;
        }

        let loadingCustomers = null;
        if (props.isFechingSalesCustomers) {
            loadingCustomers = <i className="fas fa-circle-notch fa-spin loading-customers" />;
        }

        let salesCustomerOptions = [<option value="">All</option>];
        for (let i = 0; i < props.salesCustomers.length; i++) {
            salesCustomerOptions.push(<option value={props.salesCustomers[i].id}>{props.salesCustomers[i].display_name}</option>);
        }

        const handleCheckAll = (event) => {
            props.checkAll(event.target.checked);
        };

        const handleCheckDepartment = (id, event) => {
            props.checkDepartment(id, event.target.checked);
        };

        const handleSetInvoiceReportType = (event) => {
            props.setInvoiceReportType(event.target.value);
        };

        let invoicesReportContainer = (
            <Fragment>
                Sales:
                <select value={props.salesId} onChange={handleSetSalesIdInvoicesReport} className="sales-reps-select">
                    {salesOptions}
                </select>
                Customer:
                <select value={props.customerId} onChange={handleSetCustomerId}>
                    {salesCustomerOptions}
                </select>
                {loadingCustomers}
            </Fragment>
        );
        if (parseInt(props.invoiceReportType) === 2) {
            invoicesReportContainer = (
                <Fragment>
                    Customer:
                    <select value={props.customerId} onChange={handleSetCustomerId}>
                        {customerOptions}
                    </select>
                </Fragment>
            );
        }

        content = (
            <Fragment>
                <div className="invoice-type-container">
                    Invoice Type:
                    <select value={props.invoiceReportType} onChange={handleSetInvoiceReportType} className="sales-reps-select">
                        <option value="1">By Sales Rep</option>
                        <option value="2">By Customer</option>
                    </select>
                </div>
                <hr />
                <div className="field invoices-report-container">
                    {invoicesReportContainer}
                    <button className="sales-report-button" onClick={props.exportInvoicesReport} disabled={props.exportingInvoicesReport}>{exportInvoicesReportButtonLabel}</button>
                </div>
                <div className="department-title">
                    <div className="title">Departments to export:</div>
                    <div className="select-all">
                        <input type="checkbox" onClick={handleCheckAll} /> Select All
                    </div>
                </div>
                <div className="departments-container">
                    {props.departments.map((department) => {
                        let value = props.selectedDepartments.includes(parseInt(department.id));
                        return (
                            <div className="department">
                                <input
                                    type="checkbox"
                                    value={value}
                                    checked={value}
                                    onClick={handleCheckDepartment.bind(this, department.id)}
                                />
                                {department.name}
                            </div>
                        );
                    })}
                </div>
            </Fragment>
        );
    } else if (activeTab === 5) {   // Combined Invoices Report
        let exportCombinedReportButtonLabel = <Fragment><i class="fas fa-file-excel" /> Export</Fragment>;
        if (props.exportingCombinedReport) {
            exportCombinedReportButtonLabel = <i className="fas fa-circle-notch fa-spin" />;
        }

        content = (
            <Fragment>
                <div className="field">
                    From:
                    <DayPickerInput
                        formatDate={formatDate}
                        parseDate={parseDate}
                        component={(props) => {
                            let value = props.value;
                            if (!props.value) {
                                value = '';
                            }
                            return <input {...props} value={value} />;
                        }}
                        placeholder=""
                        className="date-picker-field"
                        onDayChange={handleSetFromDate}
                        value={props.fromDate == null || props.fromDate === '0000-00-00' ? null : moment(props.fromDate).format('MM/DD/YYYY')}
                    />
                    <Tooltip title="Clear Date" position="bottom" arrow size="small" className="action-tooltip">
                        <i className="far fa-calendar-times" onClick={props.setFromDate.bind(this, null)} />
                    </Tooltip>
                    To:
                    <DayPickerInput
                        formatDate={formatDate}
                        parseDate={parseDate}
                        component={(props) => {
                            let value = props.value;
                            if (!props.value) {
                                value = '';
                            }
                            return <input {...props} value={value} />;
                        }}
                        placeholder=""
                        className="date-picker-field"
                        onDayChange={handleSetToDate}
                        value={props.toDate == null || props.toDate === '0000-00-00' ? '' : moment(props.toDate).format('MM/DD/YYYY')}
                    />
                    <Tooltip title="Clear Date" position="bottom" arrow size="small" className="action-tooltip">
                        <i className="far fa-calendar-times" onClick={props.setToDate.bind(this, null)} />
                    </Tooltip>
                    <button className="sales-report-button" onClick={props.exportCombinedReport} disabled={props.exportingCombinedReport}>{exportCombinedReportButtonLabel}</button>
                </div>
            </Fragment>
        );
    } else if (activeTab === 6) {   // Shipping Report
        let exportShippingReportButtonLabel = <Fragment><i class="fas fa-file-excel" /> Export</Fragment>;
        if (props.exportingShippingReport) {
            exportShippingReportButtonLabel = <i className="fas fa-circle-notch fa-spin" />;
        }

        let uploadShippingReportButtonLabel = <Fragment><i class="fas fa-cloud-upload-alt" /> Upload</Fragment>;
        if (props.uploadingShippingReport) {
            uploadShippingReportButtonLabel = <i className="fas fa-circle-notch fa-spin" />;
        }

        const handleUploadShippingFile = () => {
            props.uploadShippingFile(acceptedFiles[0]);
        };

        let selectedFile = null;
        let uploadButton = null;
        if (acceptedFiles.length > 0) {
            selectedFile = (
                <div className="shipping-selected-file">
                    <b>Selected File:</b> {acceptedFiles[0].name}
                </div>
            );
            uploadButton = <button className="shipping-upload-report-button" onClick={handleUploadShippingFile} disabled={props.uploadingShippingReport}>{uploadShippingReportButtonLabel}</button>;
        }

        content = (
            <Fragment>
                <div className="field shipping-report-container">
                    From:
                    <DayPickerInput
                        formatDate={formatDate}
                        parseDate={parseDate}
                        component={(props) => {
                            let value = props.value;
                            if (!props.value) {
                                value = '';
                            }
                            return <input {...props} value={value} />;
                        }}
                        placeholder=""
                        className="date-picker-field"
                        onDayChange={handleSetFromDate}
                        value={props.fromDate == null || props.fromDate === '0000-00-00' ? null : moment(props.fromDate).format('MM/DD/YYYY')}
                    />
                    <Tooltip title="Clear Date" position="bottom" arrow size="small" className="action-tooltip">
                        <i className="far fa-calendar-times" onClick={props.setFromDate.bind(this, null)} />
                    </Tooltip>
                    To:
                    <DayPickerInput
                        formatDate={formatDate}
                        parseDate={parseDate}
                        component={(props) => {
                            let value = props.value;
                            if (!props.value) {
                                value = '';
                            }
                            return <input {...props} value={value} />;
                        }}
                        placeholder=""
                        className="date-picker-field"
                        onDayChange={handleSetToDate}
                        value={props.toDate == null || props.toDate === '0000-00-00' ? '' : moment(props.toDate).format('MM/DD/YYYY')}
                    />
                    <Tooltip title="Clear Date" position="bottom" arrow size="small" className="action-tooltip">
                        <i className="far fa-calendar-times" onClick={props.setToDate.bind(this, null)} />
                    </Tooltip>
                    <div className="shipping-method-container">
                        Shipping Method:
                        <select value={props.shippingMethod} onChange={handleSetShippingMethod} className="sales-reps-select">
                            <option value="">All</option>
                            <option value="FedEx">FedEx</option>
                            <option value="UPS">UPS</option>
                            <option value="FedEx Freight">FedEx Freight</option>
                            <option value="FedEx Overnight">FedEx Overnight</option>
                            <option value="Customer Account">Customer Account</option>
                            <option value="Delivery Service">Delivery Service</option>
                            <option value="Other Freight">Other Freight</option>
                            <option value="ACM truck">ACM truck</option>
                            <option value="Will Call">Will Call</option>
                        </select>
                    </div>
                    <button className="shipping-export-report-button" onClick={props.exportShippingReport} disabled={props.exportingShippingReport}>{exportShippingReportButtonLabel}</button>
                </div>
                <div className="field upload-file shipping-report-upload-container">
                    Upload Shipping Report:
                    <div {...getRootProps({ className: 'dropzone-container' })}>
                        <input {...getInputProps()} />
                        Click here to select file
                    </div>
                    {uploadButton}
                </div>
                {selectedFile}
            </Fragment>
        );
    } else if (activeTab === 7) {   // Mailing Report
        let exportMailingReportButtonLabel = <Fragment><i class="fas fa-file-excel" /> Export</Fragment>;
        if (props.exportingMailingReport) {
            exportMailingReportButtonLabel = <i className="fas fa-circle-notch fa-spin" />;
        }

        content = (
            <Fragment>
                <div className="field shipping-report-container">
                    Date:
                    <DayPickerInput
                        formatDate={formatDate}
                        parseDate={parseDate}
                        component={(props) => {
                            let value = props.value;
                            if (!props.value) {
                                value = '';
                            }
                            return <input {...props} value={value} />;
                        }}
                        placeholder=""
                        className="date-picker-field"
                        onDayChange={handleSetToDate}
                        value={props.toDate == null || props.toDate === '0000-00-00' ? '' : moment(props.toDate).format('MM/DD/YYYY')}
                    />
                    <Tooltip title="Clear Date" position="bottom" arrow size="small" className="action-tooltip">
                        <i className="far fa-calendar-times" onClick={props.setToDate.bind(this, null)} />
                    </Tooltip>
                    <button className="shipping-export-report-button" onClick={props.exportMailingReport} disabled={props.exportingMailingReport}>{exportMailingReportButtonLabel}</button>
                </div>
            </Fragment>
        );
    } else if (activeTab === 8) {   // Yearly Sales Report
        let exportYearlySalesReportButtonLabel = <Fragment><i class="fas fa-file-excel" /> Export</Fragment>;
        if (props.exportingYearlySalesReport) {
            exportYearlySalesReportButtonLabel = <i className="fas fa-circle-notch fa-spin" />;
        }

        let salesOptions = [<option value="">Select Sales Rep</option>, <option value="0" className="all-sales">All Sales Rep</option>];
        for (let i = 0; i < props.salesRep.length; i++) {
            salesOptions.push(<option value={props.salesRep[i].id}>{props.salesRep[i].name}</option>);
        }

        let customerOptions = [<option value="">Select Customer</option>, <option value="0" className="all-sales">All Customers</option>];
        for (let i = 0; i < props.customers.length; i++) {
            if (parseInt(props.salesId) === 0) {
                customerOptions.push(<option value={props.customers[i].id}>{props.customers[i].display_name}</option>);
            } else if (parseInt(props.salesId) === parseInt(props.customers[i].sales_rep_id)) {
                customerOptions.push(<option value={props.customers[i].id}>{props.customers[i].display_name}</option>);
            }
        }

        content = (
            <div className="field">
                Sales:
                <select value={props.salesId} onChange={handleSetSalesId} className="sales-reps-select">
                    {salesOptions}
                </select>
                Customer:
                <select value={props.customerId} onChange={handleSetCustomerId} className="sales-reps-select">
                    {customerOptions}
                </select>
                From:
                <DayPickerInput
                    formatDate={formatDate}
                    parseDate={parseDate}
                    component={(props) => {
                        let value = props.value;
                        if (!props.value) {
                            value = '';
                        }
                        return <input {...props} value={value} />;
                    }}
                    placeholder=""
                    className="date-picker-field"
                    onDayChange={handleSetFromDate}
                    value={props.fromDate == null || props.fromDate === '0000-00-00' ? null : moment(props.fromDate).format('MM/DD/YYYY')}
                />
                <Tooltip title="Clear Date" position="bottom" arrow size="small" className="action-tooltip">
                    <i className="far fa-calendar-times" onClick={props.setFromDate.bind(this, null)} />
                </Tooltip>
                To:
                <DayPickerInput
                    formatDate={formatDate}
                    parseDate={parseDate}
                    component={(props) => {
                        let value = props.value;
                        if (!props.value) {
                            value = '';
                        }
                        return <input {...props} value={value} />;
                    }}
                    placeholder=""
                    className="date-picker-field"
                    onDayChange={handleSetToDate}
                    value={props.toDate == null || props.toDate === '0000-00-00' ? '' : moment(props.toDate).format('MM/DD/YYYY')}
                />
                <Tooltip title="Clear Date" position="bottom" arrow size="small" className="action-tooltip">
                    <i className="far fa-calendar-times" onClick={props.setToDate.bind(this, null)} />
                </Tooltip>
                <button className="shipping-export-report-button" onClick={props.exportYearlySalesReport} disabled={props.exportingYearlySalesReport}>
                    {exportYearlySalesReportButtonLabel}
                </button>
            </div>
        );
    } else if (activeTab === 9) {   // Job Status Report
        let exportJobStatusReportButtonLabel = <Fragment><i class="fas fa-file-excel" /> Export</Fragment>;
        if (props.exportingJobStatusReport) {
            exportJobStatusReportButtonLabel = <i className="fas fa-circle-notch fa-spin" />;
        }

        let salesOptions = [<option value="">Select Sales Rep</option>, <option value="0" className="all-sales">All Sales Rep</option>];
        for (let i = 0; i < props.salesRep.length; i++) {
            salesOptions.push(<option value={props.salesRep[i].id}>{props.salesRep[i].name}</option>);
        }

        content = (
            <div className="field">
                Sales:
                <select value={props.salesId} onChange={handleSetSalesId} className="sales-reps-select">
                    {salesOptions}
                </select>
                Jobs:
                <select value={props.jobsType} onChange={handleSetJobsType} className="sales-reps-select">
                    <option value="0" className="all-sales">All Jobs</option>
                    <option value="1">Open Jobs</option>
                    <option value="2">Finished Jobs</option>
                </select>
                From:
                <DayPickerInput
                    formatDate={formatDate}
                    parseDate={parseDate}
                    component={(props) => {
                        let value = props.value;
                        if (!props.value) {
                            value = '';
                        }
                        return <input {...props} value={value} />;
                    }}
                    placeholder=""
                    className="date-picker-field"
                    onDayChange={handleSetFromDate}
                    value={props.fromDate == null || props.fromDate === '0000-00-00' ? null : moment(props.fromDate).format('MM/DD/YYYY')}
                />
                <Tooltip title="Clear Date" position="bottom" arrow size="small" className="action-tooltip">
                    <i className="far fa-calendar-times" onClick={props.setFromDate.bind(this, null)} />
                </Tooltip>
                To:
                <DayPickerInput
                    formatDate={formatDate}
                    parseDate={parseDate}
                    component={(props) => {
                        let value = props.value;
                        if (!props.value) {
                            value = '';
                        }
                        return <input {...props} value={value} />;
                    }}
                    placeholder=""
                    className="date-picker-field"
                    onDayChange={handleSetToDate}
                    value={props.toDate == null || props.toDate === '0000-00-00' ? '' : moment(props.toDate).format('MM/DD/YYYY')}
                />
                <Tooltip title="Clear Date" position="bottom" arrow size="small" className="action-tooltip">
                    <i className="far fa-calendar-times" onClick={props.setToDate.bind(this, null)} />
                </Tooltip>
                <button className="shipping-export-report-button" onClick={props.exportJobStatusReport} disabled={props.exportingJobStatusReport}>
                    {exportJobStatusReportButtonLabel}
                </button>
            </div>
        );
    } else if (activeTab === 10) {   // Weekly Report
        let exportWeeklyReportButtonLabel = <Fragment><i class="fas fa-file-excel" /> Export</Fragment>;
        if (props.exportingWeeklyReport) {
            exportWeeklyReportButtonLabel = <i className="fas fa-circle-notch fa-spin" />;
        }

        let salesOptions = [<option value="">Select Sales Rep</option>, <option value="0" className="all-sales">All Sales Rep</option>];
        for (let i = 0; i < props.salesRep.length; i++) {
            salesOptions.push(<option value={props.salesRep[i].id}>{props.salesRep[i].name}</option>);
        }

        content = (
            <div className="field">
                Sales:
                <select value={props.salesId} onChange={handleSetSalesId} className="sales-reps-select">
                    {salesOptions}
                </select>
                From:
                <DayPickerInput
                    formatDate={formatDate}
                    parseDate={parseDate}
                    component={(props) => {
                        let value = props.value;
                        if (!props.value) {
                            value = '';
                        }
                        return <input {...props} value={value} />;
                    }}
                    placeholder=""
                    className="date-picker-field"
                    onDayChange={handleSetFromDate}
                    value={props.fromDate == null || props.fromDate === '0000-00-00' ? null : moment(props.fromDate).format('MM/DD/YYYY')}
                />
                <Tooltip title="Clear Date" position="bottom" arrow size="small" className="action-tooltip">
                    <i className="far fa-calendar-times" onClick={props.setFromDate.bind(this, null)} />
                </Tooltip>
                To:
                <DayPickerInput
                    formatDate={formatDate}
                    parseDate={parseDate}
                    component={(props) => {
                        let value = props.value;
                        if (!props.value) {
                            value = '';
                        }
                        return <input {...props} value={value} />;
                    }}
                    placeholder=""
                    className="date-picker-field"
                    onDayChange={handleSetToDate}
                    value={props.toDate == null || props.toDate === '0000-00-00' ? '' : moment(props.toDate).format('MM/DD/YYYY')}
                />
                <Tooltip title="Clear Date" position="bottom" arrow size="small" className="action-tooltip">
                    <i className="far fa-calendar-times" onClick={props.setToDate.bind(this, null)} />
                </Tooltip>
                <button className="shipping-export-report-button" onClick={props.exportWeeklyReport} disabled={props.exportingWeeklyReport}>
                    {exportWeeklyReportButtonLabel}
                </button>
            </div>
        );
    }

    let customerReportTab = null;
    let salesReportTab = null;
    let commissionReportTab = null;
    let unitCostReportTab = null;
    let combinedInvoicesReportTab = null;
    let shippingReportTab = null;
    let mailingReportTab = null;
    let yearlySalesReportTab = null;
    let jobStatusReportTab = null;
    let weeklyReportTab = null;

    if (props.user && parseInt(props.user.id) === 63) {
        salesReportTab = <div class={'tab' + (activeTab === 2 ? ' active' : '')} onClick={props.setActiveTab.bind(this, 2)}>Sales</div>;
        shippingReportTab = <div class={'tab' + (activeTab === 6 ? ' active' : '')} onClick={props.setActiveTab.bind(this, 6)}>Shipping</div>;
    }

    if (props.user && parseInt(props.user.id) === 61) {     // Hugh (61)
        customerReportTab = <div class={'tab' + (activeTab === 1 ? ' active' : '')} onClick={props.setActiveTab.bind(this, 1)}>Customer</div>;
        salesReportTab = <div class={'tab' + (activeTab === 2 ? ' active' : '')} onClick={props.setActiveTab.bind(this, 2)}>Sales</div>;
        commissionReportTab = <div class={'tab' + (activeTab === 3 ? ' active' : '')} onClick={props.setActiveTab.bind(this, 3)}>Commission</div>;
        unitCostReportTab = <div class={'tab' + (activeTab === 4 ? ' active' : '')} onClick={props.setActiveTab.bind(this, 4)}>Unit Cost</div>;
        combinedInvoicesReportTab = <div class={'tab' + (activeTab === 5 ? ' active' : '')} onClick={props.setActiveTab.bind(this, 5)}>Combined Invoices</div>;
        shippingReportTab = <div class={'tab' + (activeTab === 6 ? ' active' : '')} onClick={props.setActiveTab.bind(this, 6)}>Shipping</div>;
        mailingReportTab = <div class={'tab' + (activeTab === 7 ? ' active' : '')} onClick={props.setActiveTab.bind(this, 7)}>Mail</div>;
        yearlySalesReportTab = <div class={'tab' + (activeTab === 8 ? ' active' : '')} onClick={props.setActiveTab.bind(this, 8)}>Sales Rep Yearly Sales</div>;
        jobStatusReportTab = <div class={'tab' + (activeTab === 9 ? ' active' : '')} onClick={props.setActiveTab.bind(this, 9)}>Job Status</div>;
        weeklyReportTab = <div class={'tab' + (activeTab === 10 ? ' active' : '')} onClick={props.setActiveTab.bind(this, 10)}>Weekly Report</div>;
    }

    if (props.user != null && [28, 34, 64, 1147, 2419].includes(parseInt(props.user.id))) {     // Heather (28), Lupe (34), Christy (64), Amy (1147), Stephanie (2419)
        shippingReportTab = <div class={'tab' + (activeTab === 6 ? ' active' : '')} onClick={props.setActiveTab.bind(this, 6)}>Shipping</div>;
    }

    return (
        <Dialog
            name="quick-postage-modal"
            modal={false}
            actions={[<button className="close-button" onClick={hideHandler}>Close</button>]}
            contentStyle={{ width: 1000 }}
            bodyStyle={{ overflow: 'initial' }}
            className="quick-postage-form-dialog"
        >
            <div className="quick-postage-form-container">
                <div className="title">Customer Quick Check</div>
                <div class="tabs-container">
                    {customerReportTab}
                    {salesReportTab}
                    {commissionReportTab}
                    {unitCostReportTab}
                    {combinedInvoicesReportTab}
                    {shippingReportTab}
                    {mailingReportTab}
                    {yearlySalesReportTab}
                    {jobStatusReportTab}
                    {weeklyReportTab}
                </div>
                <div className="tabs-content">
                    {content}
                </div>
            </div>
        </Dialog >
    );
});

export default Form;
