import Immutable from "immutable";
import * as types from "./QuickPostageEscrow.types";

const initialState = {
    customers: [],
    salesCustomers: [],
    companyId: "",
    customerId: "",
    company: null,
    customer: null,
    report: null,
    isFechingCustomers: false,
    isFechingSalesCustomers: false,
    isFechingReport: false,
    activeTab: 1,
    salesId: "",
    saleReps: [],
    invoicesSalesReps: [],
    fromDate: null,
    toDate: null,
    paidDate: null,
    exportingSalesReport: false,
    exportingARAgingReport: false,
    exportingCommissionReport: false,
    exportingInvoicesReport: false,
    exportingCombinedReport: false,
    file: null,
    uploadingFile: false,
    departments: [],
    selectedDepartments: [],
    invoiceReportType: 1,
    sendingSalesReport: false,
    sendingSalesNormReport: false,
    salesReportInfoChanged: false,
    shippingMethod: "",
    exportingShippingReport: false,
    uploadingShippingReport: false,
    exportingMailingReport: false,
    exportingYearlySalesReport: false,
    exportingJobStatusReport: false,
    exportingWeeklyReport: false,
    jobsType: "0",
};

export default function (state = initialState, action) {
    state = Immutable.fromJS(state);
    switch (action.type) {
        case types.FETCH_CUSTOMERS:
            return state.setIn(["isFechingCustomers"], !action.payload).toJS();

        case types.FETCH_CUSTOMERS_SUCCESS:
            return state
                .setIn(["isFechingCustomers"], false)
                .setIn(["customers"], action.payload.customers)
                .setIn(["saleReps"], action.payload.saleReps)
                .setIn(["invoicesSalesReps"], action.payload.invoicesSalesReps)
                .setIn(["departments"], action.payload.departments)
                .setIn(["selectedDepartments"], [])
                .toJS();

        case types.FETCH_CUSTOMERS_ERROR:
            return state.setIn(["isFechingCustomers"], false).toJS();

        case types.FETCH_SALES_CUSTOMERS:
            return state
                .setIn(["isFechingSalesCustomers"], true)
                .setIn(["customerId"], "")
                .toJS();

        case types.FETCH_SALES_CUSTOMERS_SUCCESS:
            return state
                .setIn(["isFechingSalesCustomers"], false)
                .setIn(["salesCustomers"], action.payload)
                .toJS();

        case types.FETCH_SALES_CUSTOMERS_ERROR:
            return state.setIn(["isFechingSalesCustomers"], false).toJS();

        case types.FETCH_REPORT:
            return state
                .setIn(["isFechingReport"], !action.payload)
                .setIn(["report"], null)
                .toJS();

        case types.FETCH_REPORT_SUCCESS:
            return state
                .setIn(["isFechingReport"], false)
                .setIn(["salesReportInfoChanged"], false)
                .setIn(["report"], action.payload)
                .toJS();

        case types.FETCH_REPORT_ERROR:
            return state.setIn(["isFechingReport"], false).toJS();

        case types.SET_COMPANY_ID:
            return state.setIn(["companyId"], action.payload).toJS();

        case types.SET_COMPANY:
            return state.setIn(["company"], action.payload).toJS();

        case types.SET_ACTIVE_TAB:
            return state
                .setIn(["activeTab"], action.payload)
                .setIn(["report"], null)
                .toJS();

        case types.SET_SALES_ID:
            return state.setIn(["salesId"], action.payload).toJS();

        case types.SET_FROM_DATE:
            return state.setIn(["fromDate"], action.payload).toJS();

        case types.SET_PAID_DATE:
            return state.setIn(["paidDate"], action.payload).toJS();

        case types.SET_TO_DATE:
            return state.setIn(["toDate"], action.payload).toJS();

        case types.RESET_REPORT:
            return state.setIn(["report"], null).toJS();

        case types.EXPORT_SALES_REPORT:
            return state
                .setIn(["exportingSalesReport"], !action.payload)
                .toJS();

        case types.EXPORT_SALES_REPORT_SUCCESS:
            return state.setIn(["exportingSalesReport"], false).toJS();

        case types.EXPORT_SALES_REPORT_ERROR:
            return state.setIn(["exportingSalesReport"], false).toJS();

        case types.EXPORT_COMMISSION_REPORT:
            return state
                .setIn(["exportingCommissionReport"], !action.payload)
                .toJS();

        case types.EXPORT_COMMISSION_REPORT_SUCCESS:
            return state.setIn(["exportingCommissionReport"], false).toJS();

        case types.EXPORT_COMMISSION_REPORT_ERROR:
            return state.setIn(["exportingCommissionReport"], false).toJS();

        case types.EXPORT_INVOICES_REPORT:
            return state.setIn(["exportingInvoicesReport"], true).toJS();

        case types.EXPORT_INVOICES_REPORT_SUCCESS:
            return state.setIn(["exportingInvoicesReport"], false).toJS();

        case types.EXPORT_INVOICES_REPORT_ERROR:
            return state.setIn(["exportingInvoicesReport"], false).toJS();

        case types.EXPORT_COMBINED_REPORT:
            return state.setIn(["exportingCombinedReport"], true).toJS();

        case types.EXPORT_COMBINED_REPORT_SUCCESS:
            return state.setIn(["exportingCombinedReport"], false).toJS();

        case types.EXPORT_COMBINED_REPORT_ERROR:
            return state.setIn(["exportingCombinedReport"], false).toJS();

        case types.EXPORT_AR_AGING_REPORT:
            return state
                .setIn(["exportingARAgingReport"], !action.payload)
                .toJS();

        case types.EXPORT_AR_AGING_REPORT_SUCCESS:
            return state.setIn(["exportingARAgingReport"], false).toJS();

        case types.EXPORT_AR_AGING_REPORT_ERROR:
            return state.setIn(["exportingARAgingReport"], false).toJS();

        case types.EXPORT_SHIPPING_REPORT:
            return state
                .setIn(["exportingShippingReport"], !action.payload)
                .toJS();

        case types.EXPORT_SHIPPING_REPORT_SUCCESS:
            return state.setIn(["exportingShippingReport"], false).toJS();

        case types.EXPORT_SHIPPING_REPORT_ERROR:
            return state.setIn(["exportingShippingReport"], false).toJS();

        case types.EXPORT_MAILING_REPORT:
            return state.setIn(["exportingMailingReport"], true).toJS();

        case types.EXPORT_MAILING_REPORT_SUCCESS:
            return state.setIn(["exportingMailingReport"], false).toJS();

        case types.EXPORT_MAILING_REPORT_ERROR:
            return state.setIn(["exportingMailingReport"], false).toJS();

        case types.EXPORT_YEARLY_SALES_REPORT:
            return state.setIn(["exportingYearlySalesReport"], true).toJS();

        case types.EXPORT_YEARLY_SALES_REPORT_SUCCESS:
            return state.setIn(["exportingYearlySalesReport"], false).toJS();

        case types.EXPORT_YEARLY_SALES_REPORT_ERROR:
            return state.setIn(["exportingYearlySalesReport"], false).toJS();

        case types.EXPORT_JOB_STATUS_REPORT:
            return state.setIn(["exportingJobStatusReport"], true).toJS();

        case types.EXPORT_JOB_STATUS_REPORT_SUCCESS:
            return state.setIn(["exportingJobStatusReport"], false).toJS();

        case types.EXPORT_JOB_STATUS_REPORT_ERROR:
            return state.setIn(["exportingJobStatusReport"], false).toJS();

        case types.EXPORT_WEEKLY_REPORT:
            return state.setIn(["exportingWeeklyReport"], true).toJS();

        case types.EXPORT_WEEKLY_REPORT_SUCCESS:
            return state.setIn(["exportingWeeklyReport"], false).toJS();

        case types.EXPORT_WEEKLY_REPORT_ERROR:
            return state.setIn(["exportingWeeklyReport"], false).toJS();

        case types.SEND_SALES_REPORT:
            return state.setIn(["sendingSalesReport"], true).toJS();

        case types.SEND_SALES_REPORT_SUCCESS:
            return state.setIn(["sendingSalesReport"], false).toJS();

        case types.SEND_SALES_REPORT_ERROR:
            return state.setIn(["sendingSalesReport"], false).toJS();

        case types.SEND_SALES_NORM_REPORT:
            return state.setIn(["sendingSalesNormReport"], true).toJS();

        case types.SEND_SALES_NORM_REPORT_SUCCESS:
            return state.setIn(["sendingSalesNormReport"], false).toJS();

        case types.SEND_SALES_NORM_REPORT_ERROR:
            return state.setIn(["sendingSalesNormReport"], false).toJS();

        case types.UPLOAD_FILE:
            return state.setIn(["uploadingFile"], true).toJS();

        case types.UPLOAD_FILE_SUCCESS:
            return state
                .setIn(["uploadingFile"], false)
                .setIn(["file"], null)
                .toJS();

        case types.UPLOAD_FILE_ERROR:
            return state.setIn(["uploadingFile"], false).toJS();

        case types.UPLOAD_SHIPPING_FILE:
            return state.setIn(["uploadingShippingReport"], true).toJS();

        case types.UPLOAD_SHIPPING_FILE_SUCCESS:
            return state
                .setIn(["uploadingShippingReport"], false)
                .setIn(["file"], null)
                .toJS();

        case types.UPLOAD_SHIPPING_FILE_ERROR:
            return state.setIn(["uploadingShippingReport"], false).toJS();

        case types.SET_FILE:
            return state.setIn(["file"], action.payload).toJS();

        case types.SET_CUSTOMER_ID:
            return state.setIn(["customerId"], action.payload).toJS();

        case types.SET_INVOICE_REPORT_TYPE:
            return state.setIn(["invoiceReportType"], action.payload).toJS();

        case types.CHECK_DEPARTMENT:
            return state
                .updateIn(["selectedDepartments"], (selectedDepartments) => {
                    let elements = selectedDepartments.toJS();

                    if (elements.includes(action.payload.id)) {
                        elements = elements.filter(
                            (element) => element !== action.payload.id
                        );
                    } else {
                        elements.push(action.payload.id);
                    }

                    return elements;
                })
                .toJS();

        case types.CHECK_ALL:
            return state
                .updateIn(["selectedDepartments"], (selectedDepartments) => {
                    if (action.payload.value) {
                        const elements = [];
                        for (
                            let i = 0;
                            i < action.payload.departments.length;
                            i++
                        ) {
                            elements.push(
                                parseInt(action.payload.departments[i].id)
                            );
                        }
                        return elements;
                    } else {
                        return [];
                    }
                })
                .toJS();

        case types.SET_SALES_REPORT_INFO_CHANGED:
            return state
                .setIn(["salesReportInfoChanged"], action.payload)
                .toJS();

        case types.SET_SHIPPING_METHOD:
            return state.setIn(["shippingMethod"], action.payload).toJS();

        case types.SET_JOBS_TYPE:
            return state.setIn(["jobsType"], action.payload).toJS();

        case types.RESET_STATE:
            return initialState;

        default:
            return state.toJS();
    }
}
