import React, { useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Form from './Form';
import * as actions from './QuickPostageEscrow.actions';
import * as selectors from './QuickPostageEscrow.selectors';
import * as loginSelectors from './../Login/Login.selectors';
import { stringifyEqualityCheck } from './../../utils';
import * as dialog from '../common/dialog';
import './QuickPostageEscrow.scss';

const QuickPostageEscrow = (props) => {
    /* State to props */
    const customers = useSelector(selectors.getCustomers, stringifyEqualityCheck);
    const companyId = useSelector(selectors.getCompanyId);
    const company = useSelector(selectors.getCompany, stringifyEqualityCheck);
    const report = useSelector(selectors.getReport, stringifyEqualityCheck);
    const isFechingCustomers = useSelector(selectors.getIsFechingCustomers);
    const isFechingReport = useSelector(selectors.getIsFechingReport);
    const activeTab = useSelector(selectors.getActiveTab);
    const salesId = useSelector(selectors.getSalesId);
    const salesRep = useSelector(selectors.getSaleReps, stringifyEqualityCheck);
    const invoicesSalesReps = useSelector(selectors.getInvoicesSalesReps, stringifyEqualityCheck);
    const fromDate = useSelector(selectors.getFromDate, stringifyEqualityCheck);
    const toDate = useSelector(selectors.getToDate, stringifyEqualityCheck);
    const paidDate = useSelector(selectors.getPaidDate, stringifyEqualityCheck);
    const exportingSalesReport = useSelector(selectors.getExportingSalesReport);
    const exportingCommissionReport = useSelector(selectors.getExportingCommissionReport);
    const exportingARAgingReport = useSelector(selectors.getExportingARAgingReport);
    const uploadingFile = useSelector(selectors.getUploadingFile);
    const customerId = useSelector(selectors.getCustomerId);
    const salesCustomers = useSelector(selectors.getSalesCustomers, stringifyEqualityCheck);
    const isFechingSalesCustomers = useSelector(selectors.getIsFechingSalesCustomers);
    const departments = useSelector(selectors.getDepartments, stringifyEqualityCheck);
    const selectedDepartments = useSelector(selectors.getSelectedDepartments, stringifyEqualityCheck);
    const exportingInvoicesReport = useSelector(selectors.getExportingInvoicesReport);
    const invoiceReportType = useSelector(selectors.getInvoiceReportType);
    const exportingCombinedReport = useSelector(selectors.getExportingCombinedReport);
    const sendingSalesReport = useSelector(selectors.getSendingSalesReport);
    const salesReportInfoChanged = useSelector(selectors.getSalesReportInfoChanged);
    const sendingSalesNormReport = useSelector(selectors.getSendingSalesNormReport);
    const shippingMethod = useSelector(selectors.getShippingMethod);
    const exportingShippingReport = useSelector(selectors.getExportingShippingReport);
    const exportingMailingReport = useSelector(selectors.getExportingMailingReport);
    const exportingYearlySalesReport = useSelector(selectors.getExportingYearlySalesReport);
    const exportingJobStatusReport = useSelector(selectors.getExportingJobStatusReport);
    const exportingWeeklyReport = useSelector(selectors.getExportingWeeklyReport);
    const uploadingShippingReport = useSelector(selectors.getUploadingShippingReport);
    const jobsType = useSelector(selectors.getJobsType);
    const user = useSelector(loginSelectors.getUser, stringifyEqualityCheck);

    /* Dispatch to props */
    const dispatch = useDispatch();
    const fetchReport = useCallback(() => dispatch(actions.fetchReport()), [dispatch]);
    const setCompanyId = useCallback((value) => dispatch(actions.setCompanyId(value)), [dispatch]);
    const setComapny = useCallback((value) => dispatch(actions.setComapny(value)), [dispatch]);
    const setActiveTab = useCallback((id) => dispatch(actions.setActiveTab(id)), [dispatch]);
    const setSalesId = useCallback((id) => dispatch(actions.setSalesId(id)), [dispatch]);
    const resetState = useCallback(() => dispatch(actions.resetState()), [dispatch]);
    const setFromDate = useCallback((value) => dispatch(actions.setFromDate(value)), [dispatch]);
    const setToDate = useCallback((value) => dispatch(actions.setToDate(value)), [dispatch]);
    const setPaidDate = useCallback((value) => dispatch(actions.setPaidDate(value)), [dispatch]);
    const exportSalesReport = useCallback(() => dispatch(actions.exportSalesReport()), [dispatch]);
    const exportCommissionReport = useCallback(() => dispatch(actions.exportCommissionReport()), [dispatch]);
    const exportARAgingReport = useCallback((name) => dispatch(actions.exportARAgingReport(name)), [dispatch]);
    const setFile = useCallback((file) => dispatch(actions.setFile(file)), [dispatch]);
    const uploadFile = useCallback((file, setFile) => dispatch(actions.uploadFile(file, setFile)), [dispatch]);
    const fetchSalesCustomers = useCallback((id) => dispatch(actions.fetchSalesCustomers(id)), [dispatch]);
    const setCustomerId = useCallback((id) => dispatch(actions.setCustomerId(id)), [dispatch]);
    const exportInvoicesReport = useCallback(() => dispatch(actions.exportInvoicesReport()), [dispatch]);
    const exportCombinedReport = useCallback(() => dispatch(actions.exportCombinedReport()), [dispatch]);
    const checkDepartment = useCallback((id, value) => dispatch(actions.checkDepartment(id, value)), [dispatch]);
    const checkAll = useCallback((value) => dispatch(actions.checkAll(value)), [dispatch]);
    const setInvoiceReportType = useCallback((value) => dispatch(actions.setInvoiceReportType(value)), [dispatch]);
    const setSalesReportInfoChanged = useCallback((value) => dispatch(actions.setSalesReportInfoChanged(value)), [dispatch]);
    const sendSalesReport = useCallback(() => dispatch(actions.sendSalesReport()), [dispatch]);
    const sendSalesNormReport = useCallback(() => dispatch(actions.sendSalesNormReport()), [dispatch]);
    const setShippingMethod = useCallback((value) => dispatch(actions.setShippingMethod(value)), [dispatch]);
    const exportShippingReport = useCallback(() => dispatch(actions.exportShippingReport()), [dispatch]);
    const exportMailingReport = useCallback(() => dispatch(actions.exportMailingReport()), [dispatch]);
    const exportYearlySalesReport = useCallback(() => dispatch(actions.exportYearlySalesReport()), [dispatch]);
    const exportJobStatusReport = useCallback(() => dispatch(actions.exportJobStatusReport()), [dispatch]);
    const exportWeeklyReport = useCallback(() => dispatch(actions.exportWeeklyReport()), [dispatch]);
    const uploadShippingFile = useCallback((file) => dispatch(actions.uploadShippingFile(file)), [dispatch]);
    const setJobsType = useCallback((value) => dispatch(actions.setJobsType(value)), [dispatch]);
    const hideQuickPostageModal = useCallback(() => dispatch(dialog.actions.hideDialog('quick-postage-modal')), [dispatch]);

    /* componentDidMount and componentWillUnmount */
    useEffect(() => {
        return () => {
            resetState();
        }
    }, [resetState]);

    return (
        <div className="quick-postage-escrow-container">
            <Form
                customers={customers}
                companyId={companyId}
                company={company}
                report={report}
                isFechingCustomers={isFechingCustomers}
                isFechingReport={isFechingReport}
                activeTab={activeTab}
                salesId={salesId}
                salesRep={salesRep}
                invoicesSalesReps={invoicesSalesReps}
                fromDate={fromDate}
                toDate={toDate}
                paidDate={paidDate}
                exportingSalesReport={exportingSalesReport}
                exportingCommissionReport={exportingCommissionReport}
                exportingARAgingReport={exportingARAgingReport}
                uploadingFile={uploadingFile}
                customerId={customerId}
                salesCustomers={salesCustomers}
                isFechingSalesCustomers={isFechingSalesCustomers}
                departments={departments}
                selectedDepartments={selectedDepartments}
                exportingInvoicesReport={exportingInvoicesReport}
                invoiceReportType={invoiceReportType}
                exportingCombinedReport={exportingCombinedReport}
                sendingSalesReport={sendingSalesReport}
                sendingSalesNormReport={sendingSalesNormReport}
                salesReportInfoChanged={salesReportInfoChanged}
                shippingMethod={shippingMethod}
                exportingShippingReport={exportingShippingReport}
                uploadingShippingReport={uploadingShippingReport}
                exportingMailingReport={exportingMailingReport}
                exportingYearlySalesReport={exportingYearlySalesReport}
                exportingJobStatusReport={exportingJobStatusReport}
                exportingWeeklyReport={exportingWeeklyReport}
                jobsType={jobsType}
                user={user}
                fetchReport={fetchReport}
                setCompanyId={setCompanyId}
                setComapny={setComapny}
                resetState={resetState}
                hideQuickPostageModal={hideQuickPostageModal}
                setActiveTab={setActiveTab}
                setSalesId={setSalesId}
                setFromDate={setFromDate}
                setToDate={setToDate}
                setPaidDate={setPaidDate}
                exportSalesReport={exportSalesReport}
                exportCommissionReport={exportCommissionReport}
                exportARAgingReport={exportARAgingReport}
                setFile={setFile}
                uploadFile={uploadFile}
                fetchSalesCustomers={fetchSalesCustomers}
                setCustomerId={setCustomerId}
                exportInvoicesReport={exportInvoicesReport}
                exportCombinedReport={exportCombinedReport}
                checkDepartment={checkDepartment}
                checkAll={checkAll}
                setInvoiceReportType={setInvoiceReportType}
                sendSalesReport={sendSalesReport}
                setSalesReportInfoChanged={setSalesReportInfoChanged}
                sendSalesNormReport={sendSalesNormReport}
                setShippingMethod={setShippingMethod}
                exportShippingReport={exportShippingReport}
                uploadShippingFile={uploadShippingFile}
                exportMailingReport={exportMailingReport}
                exportYearlySalesReport={exportYearlySalesReport}
                exportJobStatusReport={exportJobStatusReport}
                exportWeeklyReport={exportWeeklyReport}
                setJobsType={setJobsType}
            />
        </div>
    );
};

export default QuickPostageEscrow;
